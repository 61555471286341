import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getMapThunk } from "../../redux/map";
import { getPostThunk } from "../../redux/post";
import { selectPost } from "../../redux/post/selectors";
import { AppDispatch } from "../../store";
import DataBlocks from "../DataBlocks/DataBlocks";
import ContentLoader from "../Loaders/ContentLoader/ContentLoader";
import { TitlesProps } from "./useSidebar";
import styles from "./SideBar.module.scss";

export function Content({ clusterTitle, chapterTitle, mapTitle }: TitlesProps) {
  const { id: postIdByParam } = useParams();

  const { data: post, isLoading: isPostLoading } = useSelector(selectPost);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!!postIdByParam) {
      dispatch(getPostThunk(postIdByParam));
    }
  }, [dispatch, postIdByParam]);

  useEffect(
    function getMap() {
      if (post?.map_id) {
        dispatch(getMapThunk(post?.map_id));
      }
    },
    [post?.map_id, dispatch]
  );

  if (isPostLoading) {
    return <ContentLoader />;
  }

  return (
    <>
      <div className={styles.postVisible}>
        <div className={styles.foggyLayer}>
          <div className={styles.foggyAreaTop}></div>
          <div className={styles.foggyAreaBottom}></div>
        </div>
        <div className={styles.content}>
          <div className={styles.titlesContainer}>
            <div className={styles.clusterTitle}>{clusterTitle}</div>
            <div className={styles.chapterTitle}>{chapterTitle}</div>
            <div className={styles.mapTitle}>{mapTitle}</div>
          </div>
          {post && <DataBlocks post={post} />}
        </div>
      </div>
    </>
  );
}
