import React from 'react';
import { RLayerVector } from 'rlayers';
import { Fill, Stroke, Style } from "ol/style";
import useWFSLayer from './useWFSLayer';
import { GeoJSON } from 'ol/format';
import { createDiagonalStripePattern } from "../utils";

interface PolygonLayerProps {
  url: string;
  layer: any;
  order: number;
}

const PolygonHatchedLayer: React.FC<PolygonLayerProps> = ({ url, layer, order }) => {
  const { layer_url } = useWFSLayer({ url, layer });

  const hatched_colors = layer.marker?.hatched_colors;

  const hatchFill = new Fill({
    color: createDiagonalStripePattern(hatched_colors[0], hatched_colors[1], 20) || "#000000", // Фолбэк на синий цвет
  });

  const hatchStyle = new Style({
    fill: hatchFill,
    stroke: new Stroke({
      color: "#ffffff",
      width: 1,
    }),
  });

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: 'EPSG:4326' })}
      url={layer_url}
      visible={layer.visible}
      style={hatchStyle}
    />
  );
};

export default PolygonHatchedLayer;
