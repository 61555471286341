import { useSelector } from "react-redux";
import { selectPost } from "../../redux/post/selectors";
import { RootState } from "../../store";

import { useParams } from "react-router-dom";
import { useMemo } from "react";

export function useSidebar() {
  const { data } = useSelector(selectPost);
  const { data: menu } = useSelector((state: RootState) => state.menu);

  const { isSidebarOpen } = useSelector((state: RootState) => state.menu);
  const { clusterId, constructorClusterId } = useSelector(
    (state: RootState) => state.map
  );

  const { id } = useParams();

  const isConstructorMode = useSelector(
    (state: RootState) => state.constructorMode.isConstructorMode
  );

  const isMenu = isConstructorMode || !id;

  const clusterTitle = menu?.clusters?.find(
    (cluster) => cluster.id === data?.cluster_id
  )?.tag;
  const chapterTitle = menu?.chapters?.find(
    (chapter) => chapter.id === data?.chapter_id
  )?.title;
  const mapTitle = data?.title;

  return useMemo(() => {
    return {
      clusterTitle,
      chapterTitle,
      mapTitle,
      isSidebarOpen,
      clusterId,
      id,
      isMenu,
      isConstructorMode,
      constructorClusterId,
    };
  }, [
    constructorClusterId,
    clusterTitle,
    chapterTitle,
    mapTitle,
    isSidebarOpen,
    clusterId,
    id,
    isMenu,
    isConstructorMode,
  ]);
}

export type TitlesProps = {
  clusterTitle?: string;
  chapterTitle?: string;
  mapTitle?: string;
};
