import React, { useEffect, useState } from "react";
import { ContentBlock, Tag } from "../../types/content-types";
import styles from "./TagsHorizontal.module.scss";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { getLayersThunk } from "../../redux/map";
import ContentBlockBase from "../ContentBlockBase";

export interface TagsHorizontalProps {
  contentBlock: ContentBlock;
}

const TagsHorizontal: React.FC<TagsHorizontalProps> = ({ contentBlock }) => {
  const hasMaps = contentBlock.tags?.every((tag) => !!tag.map_id);
  const [active, setActive] = useState(hasMaps ? 0 : undefined);

  const dispatch = useDispatch<AppDispatch>();

  const handleTagClick = (tag: Tag, index: number) => {
    if (tag?.layer_ids) {
      dispatch(getLayersThunk(tag.layer_ids));
    }
    setActive(index);
  };

  useEffect(() => {
    if (contentBlock.tags?.[0]?.layer_ids) {
      dispatch(getLayersThunk(contentBlock.tags?.[0].layer_ids));
    }
  }, [contentBlock.tags, dispatch]);

  return (
    <ContentBlockBase contentBlock={contentBlock}>
      <div className={styles.container}>
        {contentBlock.tags?.map((tag: Tag, index: number) => (
          <div
            key={index}
            className={classNames(styles.tag, {
              [styles.interactive]: hasMaps,
              [styles.interactiveSelected]: active === index,
            })}
            onClick={() => {
              handleTagClick(tag, index);
            }}
          >
            <span className={styles.tagText}>{tag.title}</span>
          </div>
        ))}
      </div>
    </ContentBlockBase>
  );
};

export default TagsHorizontal;
