import React, { useCallback, useEffect, useRef, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useSelector, useDispatch } from "react-redux";
import {
  addContentBlocksToWorkspace,
  clearAbstractLayersInWorkspace,
  getAbstractLayersThunk,
  getConstructorBaseMapThunk,
  removeManyContentBlocksFromWorkSpace,
  reorderContentBlocksInWorkspace,
  setAbstractLayers,
  setConstructorClusterId,
  setMap,
} from "../../redux/map";
import { RootState, AppDispatch } from "../../store";
import LayerElement from "../LayerElement/LayerElement";
import styles from "./Constructor.module.scss";

import ListElement from "../LayerElement/ListElement";
import LinkItem from "../LinkItem/LinkItem";
import classNames from "classnames";
import { useSidebar } from "../SideBar/useSidebar";
import {
  AbstractLayer,
  ContentBlockInWorkspace,
} from "../../types/content-types";

type List = { [key: string]: AbstractLayer[] };

const getLocalStorageState = () => {
  return JSON.parse(localStorage.getItem("workSpace") ?? "{}");
};

function restructureAbstractLayers(abstractLayers: AbstractLayer[]) {
  const result: List = {};
  abstractLayers.forEach((item) => {
    if (!result[item.chapter_title]) {
      result[item.chapter_title] = [];
    }
    result[item.chapter_title].push(item);
  });
  return result;
}

export default function Constructor() {
  const defaultTopHeight = (window.innerHeight - 176 - 6) * 0.4;
  const [topHeight, setTopHeight] = useState(defaultTopHeight);
  const [resizerPressed, setResizerPressed] = useState<boolean>(false);
  const topRef = useRef<HTMLDivElement>(null);
  const workspaceRef = useRef<HTMLDivElement>(null);
  const containerHeight = window.innerHeight - 176 - 6;
  const maxTopHeight = containerHeight - 56;
  const workspaceHeight = containerHeight - topHeight;

  const { abstractLayers } = useSelector((state: RootState) => state.map);
  const { contentBlocksWorkspace } = useSelector(
    (state: RootState) => state.map
  );

  const { constructorClusterId } = useSidebar();

  const prevClusterId = useRef<string | null>(null);

  const layersListByChapters = restructureAbstractLayers(abstractLayers);

  const dispatch = useDispatch<AppDispatch>();

  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      if (isNaN(newIndex)) return;
      dispatch(
        reorderContentBlocksInWorkspace({
          startIndex: oldIndex,
          endIndex: newIndex,
        })
      );
    },
    [dispatch]
  );

  const toggleContentBlocksToWorkspace = (
    contenBlocks: ContentBlockInWorkspace[],
    isItemInWorkspace: boolean
  ) => {
    if (isItemInWorkspace) {
      dispatch(removeManyContentBlocksFromWorkSpace(contenBlocks));
    } else {
      dispatch(addContentBlocksToWorkspace(contenBlocks));
    }
  };

  const resizeLayersList = () => {
    if (topHeight < defaultTopHeight) {
      setTopHeight(defaultTopHeight);
    } else if (topHeight < maxTopHeight) {
      if (workspaceRef.current) {
        workspaceRef.current.scrollTop = 0;
      }
      setTopHeight(maxTopHeight);
    }
  };

  const resizeWorkspace = () => {
    if (topHeight > defaultTopHeight) {
      setTopHeight(defaultTopHeight);
    } else if (topHeight > 56) {
      if (topRef.current) {
        topRef.current.scrollTop = 0;
      }
      setTopHeight(56);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const startY = e.clientY;
    const startHeight = topHeight;

    const onMouseMove = (e: MouseEvent) => {
      const newHeight = startHeight + (e.clientY - startY);
      if (newHeight > 56 && containerHeight - newHeight > 56) {
        setTopHeight(newHeight);
      } else if (containerHeight - newHeight > 56) {
        if (topRef.current) {
          topRef.current.scrollTop = 0;
        }
        setTopHeight(56);
      } else if (newHeight > 56) {
        if (workspaceRef.current) {
          workspaceRef.current.scrollTop = 0;
        }
        if (topHeight !== containerHeight - 56)
          setTopHeight(containerHeight - 56);
      }
    };

    const onMouseUp = () => {
      setResizerPressed(false);
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    setResizerPressed(true);
    document.body.style.cursor = "row-resize";
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  useEffect(() => {
    const {
      constructorClusterId,
      abstractLayers,
      contentBlocksWorkspace,
      map_config,
    } = getLocalStorageState();

    if (
      constructorClusterId &&
      abstractLayers &&
      contentBlocksWorkspace &&
      map_config
    ) {
      dispatch(setMap(map_config));
      dispatch(setConstructorClusterId(constructorClusterId));
      prevClusterId.current = constructorClusterId;
      dispatch(setAbstractLayers(abstractLayers));
      dispatch(addContentBlocksToWorkspace(contentBlocksWorkspace));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      constructorClusterId &&
      prevClusterId.current !== constructorClusterId
    ) {
      dispatch(getConstructorBaseMapThunk(constructorClusterId));
      dispatch(getAbstractLayersThunk(constructorClusterId));
      dispatch(clearAbstractLayersInWorkspace());
      prevClusterId.current = constructorClusterId;
    }
  }, [constructorClusterId, dispatch]);

  if (!constructorClusterId) {
    return null;
  }

  return (
    <div>
      <div
        ref={topRef}
        className={classNames(styles.layersListContainer, {
          [styles.disableHover]: resizerPressed,
          [styles.disableScroll]: topHeight === 56,
        })}
        style={{
          height: topHeight,
          transitionDuration: resizerPressed ? "0ms" : "500ms",
        }}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>База слоев</div>
          <div
            className={classNames(styles.titleDetailsIconDown, {
              [styles.disabled]: topHeight === maxTopHeight,
            })}
            onClick={resizeLayersList}
          />
        </div>

        {Object.keys(layersListByChapters).map((chapter, index) => {
          const isActiveList = layersListByChapters[chapter].some(
            (layer, index) => {
              return contentBlocksWorkspace.some((cb) =>
                layer.content_blocks.some((lcb) => lcb.id === cb.id)
              );
            }
          );

          return (
            <ListElement
              key={String(constructorClusterId + chapter + index)}
              title={chapter}
              active={isActiveList}
              Content={
                <>
                  {layersListByChapters[chapter].map((layer, index) => {
                    const isItemInWorkspace = contentBlocksWorkspace.some(
                      (cb) =>
                        layer.content_blocks.some((lcb) => lcb.id === cb.id)
                    );

                    return (
                      <LinkItem
                        key={layer.id}
                        title={layer.post_title}
                        handleClick={() => {
                          toggleContentBlocksToWorkspace(
                            layer.content_blocks,
                            isItemInWorkspace
                          );
                        }}
                        checked={isItemInWorkspace}
                        style={{ marginLeft: 18 }}
                      />
                    );
                  })}
                </>
              }
            />
          );
        })}
      </div>
      <div
        className={classNames(styles.resizer, {
          [styles.active]: resizerPressed,
        })}
        onMouseDown={handleMouseDown}
      />
      <div
        ref={workspaceRef}
        className={classNames(styles.workspaceContainer, {
          [styles.disableHover]: resizerPressed,
          // [styles.disableScroll]: topHeight === 56,
          [styles.disableScroll]: topHeight === maxTopHeight,
        })}
        style={{
          height: workspaceHeight,
          transitionDuration: resizerPressed ? "0ms" : "500ms",
        }}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>Рабочая область</div>
          <div
            className={classNames(styles.titleDetailsIconUp, {
              [styles.disabled]: topHeight === 56,
            })}
            onClick={resizeWorkspace}
          />
        </div>
        <SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
          {contentBlocksWorkspace?.map((contentBlock, index) => (
            <SortableItem key={contentBlock.id}>
              <div style={{ zIndex: 2 }}>
                <LayerElement contentBlock={contentBlock} />
              </div>
            </SortableItem>
          ))}
        </SortableList>
      </div>
    </div>
  );
}
