import React from 'react';
import { useSelector } from "react-redux";
import CustomRLayerTileWMS from "./CustomWMS";

export const DefaultLayer: React.FC = () => {

const zoom = useSelector((state: any) => state.selectFeature.currentZoom);

  return (
    <>
    <CustomRLayerTileWMS
        url="https://geo.touristatlas.ru/geoserver/wms"
        params={{
            LAYERS: "admin:russia_country_boundary_line",
            FORMAT: "image/png",
            STYLES: "country_boundary_line",
            TILED: true,
        }}
    />
    <CustomRLayerTileWMS
        url="https://geo.touristatlas.ru/geoserver/wms"
        params={{
          LAYERS: "admin:russia_subject_boundary_line_wo_coastline",
          FORMAT: "image/png",
          STYLES: "subject_boundary_line",
          TILED: true,
        }}
        visible={zoom > 5}
    />
    <CustomRLayerTileWMS
        url="https://geo.touristatlas.ru/geoserver/wms"
        params={{
          LAYERS: "admin:russia_rayons_boundary_line_wo_coastline",
          FORMAT: "image/png",
          STYLES: "subject_boundary_line",
          TILED: true,
        }}
        visible={zoom > 7}
    />
    </>
  );
};

export default DefaultLayer;
