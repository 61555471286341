import { memo, useState } from "react";
import styles from "./LayerElement.module.scss";
import Checkbox from "../Checkbox/Checkbox";
import ButtonPlusMinus from "../ButtonPlusMinus/ButtonPlusMinus";
import { ContentBlockByType } from "../DataBlocks/DataBlocks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import {
  removeContentBlockFromWorkSpace,
  toggleLayerVisible,
} from "../../redux/map";

import classNames from "classnames";
import { SortableKnob } from "react-easy-sort";
import { ContentBlockInWorkspace } from "../../types/content-types";

export default memo(function LayerElement({
  contentBlock,
}: {
  contentBlock: ContentBlockInWorkspace;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const checked = contentBlock?.visible;

  const handleOnContextMenuClick = () => {
    dispatch(removeContentBlockFromWorkSpace(contentBlock));
  };

  const handleMouseDown = () => {
    setIsDragging(true);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const withNestedContent =
    !!contentBlock?.nested && contentBlock.nested.length > 0;

  const disabledTypes = [100, 101, 102];

  const filteredContentBlock: ContentBlockInWorkspace = withNestedContent
    ? {
        ...contentBlock,
        nested: contentBlock.nested!.filter(
          (block) =>
            block.type !== undefined &&
            !(
              disabledTypes.includes(block.type as number) ||
              (block.type === 10 && block.paragraph)
            )
        ),
      }
    : contentBlock;

  return (
    <div
      className={styles.elemItem}
      onContextMenu={
        handleOnContextMenuClick
          ? (e) => {
              e.preventDefault();
              handleOnContextMenuClick();
            }
          : undefined
      }
    >
      <div
        className={classNames(styles.elementContainer, {
          [styles.grabbing]: isDragging,
        })}
        onMouseDown={handleMouseDown}
      >
        {withNestedContent ? (
          <ButtonPlusMinus isOpen={isOpen} toggleOpen={toggleDropdown} />
        ) : (
          <div style={{ width: 24 }} />
        )}
        <Checkbox
          checked={checked}
          setChecked={(value) => dispatch(toggleLayerVisible(contentBlock.id))}
          style={{ marginRight: "4px" }}
        />

        <SortableKnob>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {contentBlock?.header ?? <i>Отсутствует заголовок блока</i>}
            </div>
          </div>
        </SortableKnob>
      </div>

      {isOpen && (
        <div style={{ paddingTop: 12 }}>
          <ContentBlockByType contentBlock={filteredContentBlock} />
        </div>
      )}
    </div>
  );
});
