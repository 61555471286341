import { useSelector } from "react-redux";
import styles from "./ButtonScreenshot.module.scss";
import { RootState } from "../../store";
import ExportToPDF from "../../common/ExportToPDF";
import { useMemo, useState } from "react";
import classNames from "classnames";
import { clustersData } from "../../common/clustersData";

export default function ButtonScreenshot({
  elementRef,
}: {
  elementRef: HTMLDivElement | null;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { contentBlocksWorkspace } = useSelector(
    (state: RootState) => state.map
  );
  const { constructorClusterId } = useSelector((state: RootState) => state.map);

  const cluster = clustersData.find(
    (cluster) => cluster.id === constructorClusterId
  )?.title;

  const checkedLayers = useMemo(() => {
    return contentBlocksWorkspace.filter(
      (contentBlock) => contentBlock.visible === true
    );
  }, [contentBlocksWorkspace]);

  const handleExportPDF = () => {
    if (elementRef) {
      setIsLoading(true);
      ExportToPDF(elementRef, checkedLayers, cluster).then(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <div
      className={classNames(styles.screenshotButton, {
        [styles.loading]: isLoading,
      })}
      onClick={!isLoading ? handleExportPDF : undefined}
    />
  );
}
