import React from 'react';

type HalfCircleChartProps = {
  size: number;
  topRadius: number;
  bottomRadius: number;
  topColor: string;
  bottomColor: string;
};

const HalfCircleChart: React.FC<HalfCircleChartProps> = ({
  size,
  topRadius,
  bottomRadius,
  topColor,
  bottomColor,
}) => {
  const describeArc = (
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number
  ): string => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    
    return [
      `M ${start.x} ${start.y}`,
      `A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`,
      `L ${x} ${y}`,
      `Z`,
    ].join(" ");
  };

  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ) => {
    const angleInRadians = ((angleInDegrees) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  // Пересчёт радиусов в зависимости от размера SVG
  const scale = size / Math.max(topRadius, topRadius);

  let centerX = topRadius * scale;
  let centerY = topRadius * scale;

  if (bottomRadius > topRadius) {
    centerX = bottomRadius * scale;
    centerY = topRadius * scale;
  }

  const bottomPath = describeArc(centerX, centerY, bottomRadius * scale, 0, 180);
  const topPath = describeArc(centerX, centerY, topRadius * scale, 180, 360);
  

  let fullSize = bottomRadius * scale * 2;
  if (topRadius > bottomRadius) {
    fullSize = topRadius * scale * 2;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={fullSize} height={fullSize} viewBox={`0 0 ${fullSize} ${fullSize}`}>
      {/* topPath half-circle */}
      <path d={topPath} fill={topColor} stroke="#ffffff" strokeWidth="1" />

      {/* bottomPath half-circle */}
      <path d={bottomPath} fill={bottomColor} stroke="#ffffff" strokeWidth="1" />
    </svg>
  );
};

export default HalfCircleChart;
