import classNames from "classnames";
import React from "react";
import styles from "./SideBar.module.scss";
import Alert from "../Alert/Alert";
import MenuModule from "../Menu/Menu";
import MiniSidebar from "../MiniSidebar/MiniSidebar";

type SidebarProps = {
  isSidebarOpen: boolean;
  onSidebarClose: () => void;
  onGoBack?: () => void;
  isMenu: boolean;
  Content: JSX.Element | null;
  menuType?: "atlas" | "constructor";
  clusterTitle?: string;
  chapterTitle?: string;
  mapTitle?: string;
  scrollable?: boolean;
};

export default function Sidebar({
  isSidebarOpen,
  onSidebarClose,
  onGoBack,
  isMenu,
  Content,
  menuType = "atlas",
  clusterTitle,
  chapterTitle,
  mapTitle,
  scrollable = true,
}: SidebarProps) {
  return (
    <aside>
      <MiniSidebar
        clusterTitle={clusterTitle}
        chapterTitle={chapterTitle}
        mapTitle={mapTitle}
        isOpen={!isSidebarOpen}
        type={menuType}
      />
      <div
        className={classNames(styles.sidebar, {
          [styles.sidebarClosed]: !isSidebarOpen,
        })}
      >
        <Alert />

        <button
          className={
            isSidebarOpen ? styles.closeButtonOpen : styles.closeButtonClosed
          }
          onClick={onSidebarClose}
          data-testid="closeButton"
        />
        <button
          className={!isMenu ? styles.returnButton : styles.returnButtonNone}
          onClick={onGoBack}
        />

        <div className={scrollable ? styles.scrollContent : ""}>
          {menuType === "atlas" && Content}
          <MenuModule type={menuType} visible={isMenu} />
          {menuType === "constructor" && Content}
        </div>
      </div>
    </aside>
  );
}
