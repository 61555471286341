import { RLayerTileWMS, RLayerTileWMSProps } from "rlayers";
import TileWMS from "ol/source/TileWMS";

// Создаем новый интерфейс для пропсов
interface CustomRLayerTileWMSProps extends RLayerTileWMSProps {
  crossOrigin?: string; // Добавляем опциональное свойство crossOrigin
}

class CustomRLayerTileWMS extends RLayerTileWMS {
  // Переопределяем метод createSource, чтобы добавить поддержку crossOrigin
  protected createSource(): void {
    const { params = {}, url, projection } = this.props;

    // Если crossOrigin не передан, используем значение по умолчанию "anonymous"
    const crossOriginValue: string = "anonymous";

    // Модифицируем параметры перед передачей в TileWMS
    const options = {
      params, // Обеспечиваем, что params всегда объект
      url,
      projection,
      crossOrigin: crossOriginValue, // Добавляем crossOrigin
    };

    // Создаем источник TileWMS с нашими опциями
    this.source = new TileWMS(options);
    this.eventSources = [this.ol, this.source];
  }

  render() {
    return super.render(); // Используем render родительского компонента
  }
}

export default CustomRLayerTileWMS;
