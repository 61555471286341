import { FormikProvider, useFormik } from "formik";
import { Post } from "../../types/content-types";
import styles from "./Form.module.css";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { createPost, deletePost, updatePost } from "../../common/ApiService";

import { Blocks, Select, TextField } from "../Components";

import {
  clearEmptyFields,
  removeEmptyProps,
  validateMapCode,
  validateNotEmpty,
  validateNotEmptyArray,
} from "../../common/utils";

import { useCallback, useEffect, useState } from "react";
import { getPostsListThunk } from "../../redux/menu";
import { getPostThunk } from "../../redux/post";
import { selectPost } from "../../redux/post/selectors";

import Button from "../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

const initialValues: Post = {
  id: undefined,
  chapter_id: "",
  cluster_id: "",
  type: 1,
  title: "",
  content_blocks: [],
  map_id: "",
  tag: "",
};

const validateHeader = (block: any, index: number) => {
  if (block.header === undefined) return undefined;
  if (index === 0) {
    return validateNotEmpty(block.header);
  } else {
    return undefined;
  }
};

const validate = (values: Post) => {
  const errors = {
    cluster_id: validateNotEmpty(values.cluster_id),
    chapter_id: validateNotEmpty(values.chapter_id),
    title: validateNotEmpty(values.title),
    tag: validateNotEmpty(values.tag) ?? validateMapCode(values.tag ?? ""),
    content_blocks:
      validateNotEmptyArray(values.content_blocks) ??
      values.content_blocks?.map((block, index) => ({
        // ...{ header: validateHeader(block, index) },
        ...(values.content_blocks[index].nested && {
          nested: values.content_blocks[index].nested?.map(
            (nestedBlock, nestedIndex) => ({
              header: validateHeader(nestedBlock, nestedIndex),
            })
          ),
        }),
      })),
  };
  return removeEmptyProps(errors);
};

type FormMode = "createNew" | "edit";

const prepareValues = (values: Post): Post => {
  const valuesCopy = cloneDeep(values);

  const clearedValues = clearEmptyFields(valuesCopy);

  return clearedValues;
};

export default function AddPostForm() {
  const {
    data: menu,
    isLoading,
    postList: rawPostLIst,
  } = useSelector((state: RootState) => state.menu);
  const { mapsList } = useSelector((state: RootState) => state.map);
  const { data: post } = useSelector(selectPost);

  const postList = rawPostLIst?.filter((post) => post.type === 1);
  const { id: postIdByParam } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      const prepVals = prepareValues(values);
      if (values.id) {
        submitUpdatePost(prepVals);
      } else {
        submitNewPost(prepVals);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    isValid,
    resetForm,
    setFieldValue,
    handleSubmit,
    handleChange,
    setValues,
  } = formik;

  const [mode, setMode] = useState<FormMode>("edit");

  const dispatch = useDispatch<AppDispatch>();

  function submitNewPost(values: Post) {
    createPost(values)
      .then((data) => {
        alert(`Пост опубликован. id: ${data.id}`);
        resetForm();
      })
      .catch((err) => {
        alert("Пост не опубликован. Ошибка: " + err);
      });
  }

  function submitUpdatePost(values: Post) {
    updatePost(values)
      .then(() => {
        alert(`Пост обновлен`);
      })
      .catch((err) => {
        alert("Пост не обновлен. Ошибка: " + err);
      });
  }

  const handlePostChange: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      (e) => {
        resetForm();
        const value = e.target.value ? e.target.value : "";
        setFieldValue(e.target.name, value);
        navigate(`/edit/map${value ? "/" + value : ""}`);
      },

      [navigate, resetForm, setFieldValue]
    );

  const getPosts = useCallback(() => {
    dispatch(
      getPostsListThunk({
        chapterId: values.chapter_id,
        clusterId: values.cluster_id,
      })
    );
  }, [dispatch, values.chapter_id, values.cluster_id]);

  const handleDeletePost = useCallback(() => {
    const res = window.confirm("Удаляем пост?");
    if (res === true) {
      deletePost(values.id!)
        .then((data) => {
          alert(`Пост удален ${data.id}`);
          getPosts();
          resetForm();
        })
        .catch((err) => alert(`Пост не был удален, ${err}`));
    }
  }, [getPosts, resetForm, values.id]);

  const handleModeChange = (e: any) => {
    const value = e.target.value;
    setMode(value);
    resetForm();
    navigate("/edit/map");
  };

  const isNewPost = mode === "createNew";

  const formHasBlocks = values.content_blocks.length > 0;

  const isFormAvalable =
    (!!values.chapter_id && !!values.cluster_id && isNewPost) ||
    (!isNewPost && !!values.id);

  useEffect(() => {
    getPosts();
  }, [getPosts, values.chapter_id, values.cluster_id]);

  useEffect(() => {
    if (post) {
      setValues((values) => ({ ...values, ...post }));
    }
  }, [post, setValues]);

  useEffect(() => {
    if (values.id) {
      dispatch(getPostThunk(values.id));
      navigate(`/edit/map/${values.id}`);
    }
  }, [dispatch, navigate, values.id]);

  useEffect(() => {
    if (postIdByParam) {
      setMode("edit");
      setFieldValue("id", postIdByParam);
    }
  }, [postIdByParam, setFieldValue]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className={styles.container}>
          <h4 className={styles.heading}>Редактор карты</h4>
          <div style={{ display: "inline-block" }}>
            <span>Создать новое</span>
            <input
              type="radio"
              value="createNew"
              checked={mode === "createNew"}
              onChange={handleModeChange}
            />
          </div>
          <div style={{ display: "inline-block" }}>
            Редактировать существуюющее
            <input
              type="radio"
              checked={mode === "edit"}
              value="edit"
              onChange={handleModeChange}
            />
          </div>
          <Select
            isValid={!touched.cluster_id || !errors.cluster_id}
            validationMessage={errors.cluster_id}
            onChange={handleChange}
            options={menu?.clusters || []}
            name="cluster_id"
            label="Кластер(ВУЗ)"
            value={values.cluster_id}
          />
          <Select
            isValid={!touched.chapter_id || !errors.chapter_id}
            validationMessage={errors.chapter_id}
            onChange={handleChange}
            options={menu?.chapters || []}
            name="chapter_id"
            label="Раздел"
            value={values.chapter_id}
          />
          {!isNewPost && (
            <Select
              isValid={!touched.id || !errors.id}
              validationMessage={errors.id}
              onChange={handlePostChange}
              options={postList || []}
              name="id"
              label={`Карта (${postList.length})`}
              value={values.id}
              showIds
              isChooseOptionFirst={true}
            />
          )}
          {isFormAvalable && (
            <>
              <TextField
                name="title"
                label="Название карты"
                onChange={handleChange}
                value={values.title}
                isValid={!touched.title || !errors.title}
                validationMessage={errors.title}
              />
              <TextField
                name="tag"
                label="Код"
                onChange={handleChange}
                value={values.tag}
                isValid={!touched.tag || !errors.tag}
                validationMessage={errors.tag}
              />
              <Select
                onChange={(e) => {
                  setFieldValue(`map_id`, e.target.value);
                }}
                options={mapsList}
                name={`map_id`}
                label="Выбрать загруженный JSON основного слоя"
                value={values.map_id}
              />
              {values.id && (
                <div className={styles.containerRight}>
                  <Button
                    type="button"
                    onClick={handleDeletePost}
                    status={!values.id ? "disabled" : "enabled"}
                    style={{ background: "red" }}
                    title="Удалить карту"
                  />
                </div>
              )}
              <Blocks />
              {typeof errors.content_blocks === "string" && (
                <div style={{ color: "red" }}>
                  Должен быть хотя бы один блок
                </div>
              )}
              <div className={styles.containerBetween}>
                {formHasBlocks && (
                  <Button
                    type="submit"
                    status={
                      values.content_blocks.length === 0 || isLoading
                        ? "disabled"
                        : "enabled"
                    }
                    title={!!values.id ? "Обновить карту" : "Отправить"}
                  />
                )}

                {!isValid && <div style={{ color: "red" }}>Проверьте поля</div>}
                {formHasBlocks && (
                  <Button
                    style={{ background: "red" }}
                    type="button"
                    onClick={() => {
                      localStorage.removeItem("atlas_draft");
                      resetForm();
                    }}
                    title="Сбросить форму"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </FormikProvider>
  );
}
