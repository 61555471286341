export const clustersData = [
  {
    id: "0193c514-f0eb-7517-bf08-d338cfe72284",
    title: "Алтайский край, Новосибирская область, Республика Алтай",
    tag: "НГУЭУ",
  },
  {
    id: "0193c514-f130-79a7-9671-fc8cca91c97a",
    title: "Волгоградская область, Ростовская область, Краснодарский край",
    tag: "ЮФУ",
  },
  {
    id: "0193c514-f142-7959-815f-35ef9ec16c6a",
    title: "Ставропольский край",
    tag: "СтГАУ",
  },
  {
    id: "0193c514-f154-71b2-add7-b0c2e16ca53c",
    title: "Кавказские Минеральные Воды",
    tag: "ПГУ",
  },
  {
    id: "0193c514-f15b-7bf3-8c96-40936e3e6535",
    title: "Архангельская область, Мурманская область, Республика Карелия",
    tag: "РГГМУ",
  },
  {
    id: "0193c514-f168-7b61-8e2f-faa6d7273c56",
    title: "Владимирская область",
    tag: "ВИТИГ",
  },
  {
    id: "0193c514-f173-7a28-ab26-e9964b6d0cf2",
    title: "Республика Калмыкия",
    tag: "КалмГУ",
  },
  {
    id: "0193c514-f17d-7e22-9d0b-e5edd3c68431",
    title: "Оренбургская область",
    tag: "ОГУ",
  },
];
