import { memo, useState } from "react";
import styles from "./LayerElement.module.scss";

import ButtonPlusMinus from "../ButtonPlusMinus/ButtonPlusMinus";

export default memo(function ListElement({
  title,
  active,
  Content,
}: {
  title: string;
  active: boolean;
  Content: JSX.Element;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.elemItem}>
      <div
        className={styles.elementContainer}
        style={{ cursor: "pointer", fontWeight: active ? "bold" : "normal" }}
        onClick={toggleDropdown}
      >
        <ButtonPlusMinus isOpen={isOpen} />
        <div className={styles.titleContainer}>
          <div
            className={styles.chapter}
            style={{ color: active ? "#272d34" : "" }}
          >
            {title}
          </div>
        </div>
      </div>

      {isOpen && <div style={{ paddingTop: 8 }}>{Content}</div>}
    </div>
  );
});
