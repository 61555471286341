import React from "react";
import styles from "./PageMapsDescription.module.scss";
import { ContentBlockByType } from "../DataBlocks/DataBlocks";
import { ContentBlockInWorkspace } from "../../types/content-types";

export default function PageMapsDescription({
  checkedLayersData: checkedContentBlocks,
}: {
  checkedLayersData: ContentBlockInWorkspace[];
}) {
  return (
    <div className={styles.container}>
      <div className={styles.pageTitle}>Описание элементов карты</div>
      <div className={styles.dataContainer}>
        {checkedContentBlocks.map((contentBlock, index) => {
          return (
            contentBlock.type && (
              <div key={index} className={styles.dataModule}>
                <div className={styles.dataContent}>
                  <div className={styles.postTitle}>{contentBlock.header}</div>
                  <ContentBlockByType contentBlock={contentBlock} />
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
