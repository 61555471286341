import React from "react";

type PieChartData = {
  color: string;
  value: number;
};

type DonutChartProps = {
  data: PieChartData[];
  radius: number;
  innerRadius: number;
};

type ExtendedDonutChartProps = DonutChartProps & {
    label?: string | null;
  };

const DonutChart: React.FC<ExtendedDonutChartProps> = ({ data, radius, innerRadius, label }) => {
  const size = radius * 2;
  const center = radius;

  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ) => {
    const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180);
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const createPath = (startAngle: number, endAngle: number): string => {
    const outerStart = polarToCartesian(center, center, radius, endAngle);
    const outerEnd = polarToCartesian(center, center, radius, startAngle);
    const innerStart = polarToCartesian(center, center, innerRadius, endAngle);
    const innerEnd = polarToCartesian(center, center, innerRadius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return [
      `M ${outerStart.x} ${outerStart.y}`,
      `A ${radius} ${radius} 0 ${largeArcFlag} 0 ${outerEnd.x} ${outerEnd.y}`,
      `L ${innerEnd.x} ${innerEnd.y}`,
      `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 1 ${innerStart.x} ${innerStart.y}`,
      "Z",
    ].join(" ");
  };

  const filteredData = data.filter((item) => item.value !== null);
  const total = filteredData.reduce((sum, item) => sum + (item.value || 0), 0);

  if (filteredData.length === 1) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        {/* Маска для вырезания */}
        <defs>
          <mask id="donut-hole-mask">
            {/* Внешний круг */}
            <circle cx={center} cy={center} r={radius} fill="white"/>
            {/* Внутренний круг (отверстие) */}
            <circle cx={center} cy={center} r={innerRadius} fill="black" />
          </mask>
        </defs>
        {/* Основной круг с применением маски */}
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill={filteredData[0].color}
          mask="url(#donut-hole-mask)"
          stroke="white"
          strokeWidth="1"
        />
      </svg>
    );
  }

  let startAngle = 0;
  const paths = filteredData.map((item, index) => {
    const percentage = (item.value! / total) * 100;
    const angle = (percentage / 100) * 360;
    const endAngle = startAngle + angle;

    const path = createPath(startAngle, endAngle);
    startAngle = endAngle;

    return (
      <path
        key={index}
        d={path}
        fill={item.color}
        stroke="white"
        strokeWidth="1"
      />
    );
  });

  const wrapText = (text: string, maxCharsPerLine: number) => {
    const words = text.split(" ");
    const lines: string[] = [];
    let currentLine = "";
  
    for (const word of words) {
      if ((currentLine + word).length > maxCharsPerLine) {
        lines.push(currentLine.trim());
        currentLine = word + " ";
      } else {
        currentLine += word + " ";
      }
    }
    if (currentLine.trim()) {
      lines.push(currentLine.trim());
    }
  
    return lines;
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      {paths}
      <circle cx={center} cy={center} r={innerRadius} fill="none" /> {/* Прозрачная середина */}
      {label && size > 50 ? (
      wrapText(label, Math.floor(innerRadius / 6)).map((line, index) => (
        <text
          key={index}
          x={center}
          y={center + index * (innerRadius * 0.3) - (wrapText(label, Math.floor(innerRadius / 6)).length - 1) * (innerRadius * 0.2)} // Вертикальное центрирование
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={innerRadius * 0.3}
          fill="#272d34"
          fontFamily="'Nunito', sans-serif"
          fontWeight="700"
        >
          {line}
        </text>
      ))
    ) : null}
    </svg>
  );
};

export default DonutChart;
