import React, { useCallback, useEffect } from "react";
import Select from "../Input/Select/Select";
import { useSelector, useDispatch } from "react-redux";
import {
  setClusterId,
  setChapterId,
  setConstructorClusterId,
} from "../../redux/map";
import { RootState } from "../../store";

export default function MenuCluster() {
  const { data: menu } = useSelector((state: RootState) => state.menu);
  const clustersOptions = menu?.clusters || [];

  const { clusterId, constructorClusterId } = useSelector(
    (state: RootState) => state.map
  );
  const dispatch = useDispatch();

  const isConstructorMode = useSelector(
    (state: RootState) => state.constructorMode.isConstructorMode
  );

  const handleClustersOption = useCallback(
    (value: string) => {
      if (isConstructorMode) {
        dispatch(setConstructorClusterId(value));
      } else {
        dispatch(setClusterId(value));
      }

      dispatch(setChapterId(null));
    },
    [dispatch, isConstructorMode]
  );

  useEffect(() => {
    if (isConstructorMode) {
      dispatch(setConstructorClusterId(constructorClusterId));
    }
  }, [constructorClusterId, dispatch, isConstructorMode]);

  return (
    <Select
      options={clustersOptions}
      value={isConstructorMode ? constructorClusterId : clusterId}
      placeholder={"Выберите кластер"}
      onSelectedChange={handleClustersOption}
    />
  );
}
