import React from 'react';

const TwoCircles = ({ 
  outerRadius = 60, 
  innerRadius = 40, 
  outerColor = 'blue', 
  innerColor = 'red', 
  svgSize = 120 
}) => {
  // Пересчёт радиусов в зависимости от размера SVG
  const scale = svgSize / Math.max(outerRadius, innerRadius);
  const scaledOuterRadius = outerRadius * scale;
  const scaledInnerRadius = innerRadius * scale;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={scaledOuterRadius} height={scaledOuterRadius} viewBox={`0 0 ${svgSize *2} ${svgSize*2}`}>
      {/* Внешний круг */}
      <circle cx={svgSize} cy={svgSize} r={scaledOuterRadius} fill={outerColor} stroke='#ffffff' strokeWidth="1"/>
      {/* Внутренний круг */}
      <circle cx={svgSize} cy={svgSize} r={scaledInnerRadius} fill={innerColor} />
    </svg>
  );
};

export default TwoCircles;
