import { v1 as uuid } from "uuid";
import {
  AbstractLayer,
  ContentBlockInWorkspace,
  MenuItem,
} from "./../../types/content-types";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { getPostsListThunk } from "../menu";
import {
  getConstructorBaseMap,
  getConstructorLayers,
  getLayers,
  getMap,
  getMapsList,
} from "../../common/ApiService";
import { AtlasMap } from "../../components/Map/config/interfaces";

import { maps } from "../../components/Map/config";

const reorder = function <T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type State = {
  constructorClusterId: string | null;
  clusterId: string | null;
  chapterId: string | null;
  map_config: AtlasMap | null;
  isMapLoading: boolean;
  mapsList: MenuItem[];
  abstractLayers: AbstractLayer[];
  contentBlocksWorkspace: ContentBlockInWorkspace[];
};

const initialState: State = {
  constructorClusterId: null,
  clusterId: null,
  chapterId: null,
  map_config: null,
  isMapLoading: false,
  mapsList: [],
  abstractLayers: [],
  contentBlocksWorkspace: [],
};

export const setClusterId = createAction<string | null>("SET_CLUSTER_ID");

export const setConstructorClusterId = createAction<string | null>(
  "SET_CONSTRUCTOR_CLUSTER_ID"
);

export const setChapterId = createAction<string | null>("SET_CHAPTER_ID");

export const setDefaultMap = createAction("SET_DEFAULT_MAP");

export const getMapThunk = createAsyncThunk("map/get", getMap);

export const setMap = createAction<AtlasMap>("SET_MAP");

export const getLayersThunk = createAsyncThunk("layers/get", getLayers);

export const getConstructorBaseMapThunk = createAsyncThunk(
  "constructorMap/get",
  getConstructorBaseMap
);

export const getAbstractLayersThunk = createAsyncThunk(
  "constructorLayers/get",
  getConstructorLayers
);

export const getConstructorActiveMapById = createAsyncThunk(
  "constructorMapById/get",
  getMap
);

export const getMapsListThunk = createAsyncThunk("map/getList", getMapsList);

export const toggleLayerVisible = createAction<string>("TOGGLE_LAYER_VISIBLE");

export const reorderLayers = createAction<{
  startIndex: number;
  endIndex: number;
}>("REORDER_LAYERS");
export const reorderContentBlocksInWorkspace = createAction<{
  startIndex: number;
  endIndex: number;
}>("REORDER_ABSTRACT_LAYERS");

export const setAbstractLayers = createAction<AbstractLayer[]>(
  "SET_ABSTRACT_lAYERS"
);

export const addContentBlocksToWorkspace =
  createAction<ContentBlockInWorkspace[]>("ADD_ABSTRACT_LAYER");

export const removeContentBlockFromWorkSpace =
  createAction<ContentBlockInWorkspace>("REMOVE_CONTENT_BLOCK");

export const removeManyContentBlocksFromWorkSpace = createAction<
  ContentBlockInWorkspace[]
>("REMOVE_MANY_CONTENT_BLOCKS");

export const syncAbstractLayersFromWorkSpaceToMapLayers =
  createAction("SYNC_LAYERS");

export const clearAbstractLayersInWorkspace = createAction("CLEAR_WORKSPACE");

function saveToStorage(state: State) {
  localStorage.setItem("workSpace", JSON.stringify(state));
}

const mapReducer = createReducer(initialState, (builder) => {
  builder.addCase(setClusterId, (state, action) => {
    state.clusterId = action.payload;
    getPostsListThunk({
      clusterId: action.payload!,
      chapterId: state.chapterId!,
    });
  });
  builder.addCase(setChapterId, (state, action) => {
    state.chapterId = action.payload;
    getPostsListThunk({
      clusterId: state.clusterId!,
      chapterId: action.payload!,
    });
  });
  builder.addCase(setDefaultMap, (state) => {
    state.map_config = maps[0].map_config;
  });
  builder.addCase(getMapThunk.fulfilled, (state, action) => {
    state.map_config = action.payload;
    state.isMapLoading = false;
  });
  builder.addCase(setMap, (state, action) => {
    state.map_config = action.payload;
  });
  builder.addCase(getMapThunk.pending, (state) => {
    state.isMapLoading = true;
  });
  builder.addCase(getMapsListThunk.fulfilled, (state, action) => {
    state.mapsList = action.payload;
    state.isMapLoading = false;
  });
  builder.addCase(getMapsListThunk.pending, (state) => {
    state.isMapLoading = true;
  });
  builder.addCase(getLayersThunk.fulfilled, (state, action) => {
    if (state.map_config) {
      state.map_config.layers = action.payload;
      state.isMapLoading = false;
    }
  });
  builder.addCase(getLayersThunk.pending, (state) => {
    state.isMapLoading = true;
  });

  // CONSTRUCTOR STUFF
  builder.addCase(setConstructorClusterId, (state, action) => {
    state.constructorClusterId = action.payload;
  });
  builder.addCase(getConstructorBaseMapThunk.fulfilled, (state, action) => {
    state.map_config = action.payload;
    state.isMapLoading = false;
  });
  builder.addCase(getConstructorBaseMapThunk.pending, (state) => {
    state.isMapLoading = true;
  });

  builder.addCase(getAbstractLayersThunk.fulfilled, (state, action) => {
    const layersWithIds = action.payload.map((layer) => ({
      ...layer,
      content_blocks: layer.content_blocks?.map((content_block) => ({
        ...content_block,
        map_layers: content_block.map_layers ? content_block.map_layers?.map((map_layer) => ({
          ...map_layer,
          visible: false,
          layer_id: map_layer.id
        })): [],
        id: uuid(),
        visible: false,
      })),
      id: uuid(),
    }));
    state.abstractLayers = layersWithIds;
    state.isMapLoading = false;
    saveToStorage(state);
  });

  builder.addCase(getAbstractLayersThunk.pending, (state) => {
    state.isMapLoading = true;
  });
  builder.addCase(reorderContentBlocksInWorkspace, (state, action) => {
    const { startIndex, endIndex } = action.payload;
    if (state.contentBlocksWorkspace) {
      state.contentBlocksWorkspace = reorder(
        state.contentBlocksWorkspace,
        startIndex,
        endIndex
      );
      if (state.map_config) {
        state.map_config.layers = state.contentBlocksWorkspace.flatMap(
          (item) => item.map_layers
        );
      }
    }
    saveToStorage(state);
  });

  builder.addCase(setAbstractLayers, (state, action) => {
    state.abstractLayers = action.payload;
  });

  builder.addCase(addContentBlocksToWorkspace, (state, action) => {
    action.payload.forEach((contentBlock) => {
      if (
        !state.contentBlocksWorkspace.find(
          (contentBlockWorkSpace) =>
            contentBlockWorkSpace.id === contentBlock.id
        )
      ) {
        state.contentBlocksWorkspace.unshift(...action.payload);
        if (state.map_config) {
          state.map_config.layers = state.contentBlocksWorkspace.flatMap(
            (item) => item.map_layers
          );
        }
      }
    });
    saveToStorage(state);
  });
  builder.addCase(removeContentBlockFromWorkSpace, (state, action) => {
    state.contentBlocksWorkspace = state.contentBlocksWorkspace.filter(
      (layer) => layer.id !== action.payload.id
    );
    if (state.map_config) {
      state.map_config.layers = state.contentBlocksWorkspace.flatMap(
        (item) => item.map_layers
      );
    }
    saveToStorage(state);
  });

  builder.addCase(removeManyContentBlocksFromWorkSpace, (state, action) => {
    action.payload.forEach((contentBlock) => {
      state.contentBlocksWorkspace = state.contentBlocksWorkspace.filter(
        (layer) => {
          return layer.id !== contentBlock.id;
        }
      );
    });
    if (state.map_config) {
      state.map_config.layers = state.contentBlocksWorkspace.flatMap(
        (item) => item.map_layers
      );
    }
    saveToStorage(state);
  });

  builder.addCase(toggleLayerVisible, (state, action) => {
    const targetContentBlockInWorkSpace = state.contentBlocksWorkspace.find(
      (layer) => layer.id === action.payload
    );
    if (targetContentBlockInWorkSpace) {
      const nextValue = !targetContentBlockInWorkSpace.visible;

      targetContentBlockInWorkSpace.map_layers.forEach((map_layer) => {
        const targetLayerInMapConfig = state.map_config?.layers.find(
          (layer) => layer.id === map_layer.id
        );
        map_layer.visible = nextValue;
        if (targetLayerInMapConfig) targetLayerInMapConfig.visible = nextValue;
      });
      targetContentBlockInWorkSpace.visible = nextValue;
    }
    saveToStorage(state);
  });

  builder.addCase(clearAbstractLayersInWorkspace, (state, action) => {
    state.contentBlocksWorkspace = [];
    if (state.map_config) {
      state.map_config.layers = [];
    }
    saveToStorage(state);
  });
});

export default mapReducer;
