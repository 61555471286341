import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import PageMapsDescription from "../components/PageMapsDescription/PageMapsDescription";
import { store } from "../store";
import { ContentBlockInWorkspace } from "../types/content-types";

const ExportToPDF = async (
  elementRef: HTMLDivElement | null,
  checkedContentBlocks: ContentBlockInWorkspace[],
  title?: string
) => {
  if (!elementRef) return;

  try {
    document.body.style.cursor = "wait";
    const canvas = await html2canvas(elementRef, {
      useCORS: true,
      logging: true,
    });
    const imageData = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "landscape",
      format: "a4",
    });

    const mapRatio = canvas.width / canvas.height;
    const imgWidth =
      mapRatio > 1.435 ? 287 : (canvas.width * 200) / canvas.height;
    const imgHeight =
      mapRatio > 1.435 ? (canvas.height * imgWidth) / canvas.width : 200;
    const yPos = (210 - imgHeight) / 2;
    const xPos = (297 - imgWidth) / 2;

    pdf.addImage(imageData, "PNG", xPos, yPos, imgWidth, imgHeight, "", "FAST");

    // textContent
    const textContent = `ТУРИСТСКИЙ АТЛАС. ${title ? title.toUpperCase() : ""}`;
    const canvasText = document.createElement("canvas");
    const context = canvasText.getContext("2d");

    if (context) {
      canvasText.width = canvas.width;
      canvasText.height = 100;
      const calculatedFontSize = Math.ceil(canvas.width / 87);
      context.fillStyle = "#272d34";
      context.font = `900 ${calculatedFontSize}px 'Nunito Sans'`;
      const textWidth = context.measureText(textContent).width;
      const textX = (canvas.width - textWidth) / 2;
      const textY = 50;

      context.fillText(textContent, textX, textY);

      const textImageData = canvasText.toDataURL("image/png");

      const textImgWidth = imgWidth;
      const textImgHeight = (imgHeight / canvas.height) * 100;
      const textImgY = 5;

      pdf.addImage(
        textImageData,
        "PNG",
        xPos,
        textImgY,
        textImgWidth,
        textImgHeight,
        "",
        "FAST"
      );
    }

    const hasNestedContent = checkedContentBlocks.some((block) => {
      return Array.isArray(block.nested) && block.nested.length > 0;
    });

    const filteredContentBlocks: ContentBlockInWorkspace[] =
      checkedContentBlocks.map((contentBlock) => {
        const withNestedContent =
          !!contentBlock.nested && contentBlock.nested.length > 0;
        const disabledTypes = [100, 101, 102];

        return withNestedContent
          ? {
              ...contentBlock,
              nested: contentBlock.nested!.filter(
                (block) =>
                  block.type !== undefined &&
                  !(
                    disabledTypes.includes(block.type as number) ||
                    (block.type === 10 && block.paragraph)
                  )
              ),
            }
          : contentBlock;
      });

    if (hasNestedContent) {
      pdf.addPage();
      const specificationMap = document.createElement("div");
      specificationMap.style.width = "1920px";
      specificationMap.style.height = "1358px";

      ReactDOM.render(
        <Provider store={store}>
          <PageMapsDescription checkedLayersData={filteredContentBlocks} />
        </Provider>,
        specificationMap
      );

      document.body.appendChild(specificationMap);
      const specificationCanvas = await html2canvas(specificationMap, {
        useCORS: true,
      });
      const specificationImageData = specificationCanvas.toDataURL("image/png");
      pdf.addImage(specificationImageData, "PNG", 0, 0, 297, 210, "", "FAST");
      document.body.removeChild(specificationMap);
    }

    pdf.save("Карта туристского атласа.pdf");
    document.body.style.cursor = "default";
  } catch (error) {
    console.error("Failed to export canvas as PDF:", error);
  }
};

export default ExportToPDF;
