import React, { useEffect, useState } from "react";
import styles from "./WindowManagement.module.scss";
import StatusLabel from "../StatusLabel/StatusLabel";
import { getPostsList, updatePublishing } from "../../common/ApiService";
import { MenuItem } from "../../types/content-types";
import ButtonThreeDot from "../ButtonThreeDot/ButtonThreeDot";
import { formatDateTime } from "../../common/utils";
import { orderBy } from "lodash";
import classNames from "classnames";
import MenuButton from "../MenuButton/MenuButton";
import { useMenuEdit } from "../../hooks/useMenuEditConfig";
import { clustersData } from "../../common/clustersData";
import CircleLoader from "../Loaders/CircleLoader/CircleLoader";

export type WindowManagementProps = {};

const WindowManagement: React.FC<WindowManagementProps> = () => {
  const [posts, setPosts] = useState<MenuItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof MenuItem;
    direction: "asc" | "desc";
  }>({
    key: "type",
    direction: "asc",
  });

  const { menuEditOptions, handleMenuEditSelect } = useMenuEdit();

  const fetchPosts = async () => {
    try {
      setIsLoading(true);
      const postsList = await getPostsList();

      const updatedPostsList = postsList.map((post) => {
        const clusterTag = clustersData.find(
          (cluster) => cluster.id === post.cluster_id
        )?.tag;
        return { ...post, cluster_id: clusterTag };
      });

      setIsLoading(false);
      setPosts(updatedPostsList);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const sortedPosts = orderBy(posts, sortConfig.key, sortConfig.direction);

  const prepareOptions = (status?: boolean) => {
    const publishOption: { [key: string]: string } = status
      ? { remove: "Снять с публикации" }
      : { publish: "Опубликовать" };

    const options = {
      ...publishOption,
      edit: "Редактировать",
      preview: "Просмотр",
    };

    return options;
  };

  const headers = {
    type: "Тип",
    cluster_id: "Вуз",
    title: "Название",
    updated: "Дата изменений",
    published: "Статус",
  };

  const handleSort = (field: keyof MenuItem) => {
    setSortConfig((prev) => {
      if (prev.key === field) {
        return {
          key: field,
          direction: prev.direction === "asc" ? "desc" : "asc",
        };
      } else {
        return { key: field, direction: "asc" };
      }
    });
  };

  const handleSelectAction = (post: MenuItem, option: string) => {
    if (option === "remove") {
      updatePublishing({ post_id: post.id, published: false }).then(() =>
        fetchPosts()
      );
    }
    if (option === "publish") {
      updatePublishing({ post_id: post.id, published: true }).then(() =>
        fetchPosts()
      );
    }
    if (option === "preview") {
      post.type === 1
        ? window.open(`/map/${post.id}`, "_blank")
        : window.open(post.url, "_blank");
    }
    if (option === "edit") {
      const postType = post.type === 1 ? "map" : "file";
      window.open(`/edit/${postType}/${post.id}`, "_blank");
    }
  };

  return (
    <div className={styles.container}>
      <MenuButton
        options={menuEditOptions}
        onSelect={handleMenuEditSelect}
        selectedOption="management"
        style={{
          position: "absolute",
          left: "-300px",
        }}
      />
      {isLoading && <CircleLoader />}
      <div className={styles.mainTitle}>Управление контентом</div>
      <table className={styles.table}>
        <thead>
          <tr>
            {Object.entries(headers).map(([field, title]) => (
              <th key={field}>
                <div
                  className={styles.thDiv}
                  onClick={() => handleSort(field as keyof MenuItem)}
                >
                  <p>{title}</p>
                  <div
                    className={classNames(styles.arrow, {
                      [styles.activeAsc]:
                        field === sortConfig.key &&
                        sortConfig.direction === "asc",
                      [styles.activeDesc]:
                        field === sortConfig.key &&
                        sortConfig.direction === "desc",
                    })}
                  />
                </div>
              </th>
            ))}
            <th>
              <div
                className={styles.thLastDiv}
                style={{ border: "none", paddingRight: 16 }}
              >
                Действия
              </div>
            </th>
          </tr>
        </thead>
        {!!sortedPosts.length && (
          <tbody>
            {sortedPosts.map((post, index) => (
              <tr key={index}>
                <td>
                  <StatusLabel
                    color={post.type === 1 ? "green" : "blue"}
                    title={post.type === 1 ? "Карта" : "Документ"}
                  />
                </td>
                <td>{post.cluster_id}</td>
                <td>{post.title}</td>
                <td>{formatDateTime(post.updated)}</td>
                <td>
                  <StatusLabel
                    color={post.published ? "green" : "gray"}
                    title={post.published ? "Опубликовано" : "Снято"}
                  />
                </td>
                <td>
                  <div className={styles.buttonContainer}>
                    <ButtonThreeDot
                      options={prepareOptions(post.published)}
                      onSelect={(option) => handleSelectAction(post, option)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default WindowManagement;
