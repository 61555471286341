export const poligonsData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      clusterId: "0193c514-f154-71b2-add7-b0c2e16ca53c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.404416403785596, 45.239722128840711],
            [43.406250000000104, 44.629883800764995],
            [42.487736593060042, 44.629883800764995],
            [42.487736593060042, 45.239722128840711],
          ],
        ],
      },
      properties: {
        name: " Кавказкие минеральные воды",
        otherInfo: "ПГУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f142-7959-815f-35ef9ec16c6a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.206235962000051, 45.94885012900005],
            [41.710817562000045, 46.101176649000081],
            [43.22173039200004, 46.193649361000041],
            [45.699868328000036, 45.006329780000044],
            [45.217270086000042, 44.289731481000047],
            [45.497849923000047, 44.025960007000037],
            [44.833511241000053, 43.65443190600007],
            [44.643103089000078, 43.778197205000026],
            [44.311981975000037, 43.855028480000044],
            [42.681899133000059, 43.835319650000031],
            [42.374246460000052, 44.039988383000036],
            [42.413483781000025, 44.328688879000026],
            [41.720337968000081, 44.520788994000043],
            [41.438055015000032, 44.623355005000064],
            [41.53736888800006, 44.993357619000051],
            [40.933100031000038, 45.564883337000026],
            [41.267500908000045, 45.761304977000066],
            [41.206235962000051, 45.94885012900005],
          ],
        ],
      },
      properties: {
        name: "Ставропольский край",
        otherInfo: "СтГАУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f130-79a7-9671-fc8cca91c97a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.788450651000062, 46.876681104000056],
            [38.93737275400008, 46.69275895700008],
            [39.976027515000055, 46.757089601000075],
            [41.206235962000051, 45.94885012900005],
            [41.267500908000045, 45.761304977000066],
            [40.933100031000038, 45.564883337000026],
            [41.53736888800006, 44.993357619000051],
            [41.438055015000032, 44.623355005000064],
            [41.720337968000081, 44.520788994000043],
            [41.433443490000059, 44.056295453000075],
            [40.770707678000065, 43.991112552000061],
            [40.691841648000036, 43.602102485000046],
            [40.284110723000026, 43.526648374000047],
            [40.244801679000034, 43.519157710000059],
            [39.992209432000038, 43.471024171000067],
            [39.992098946000056, 43.471003117000066],
            [39.922579198000051, 43.457557023000049],
            [38.73189721500006, 44.338072718000035],
            [37.968246729000043, 44.566173128000059],
            [37.592041981000079, 44.674747871000079],
            [37.018464016000053, 45.068880954000065],
            [36.878277352000055, 45.163486481000064],
            [37.785617971000079, 45.391555424000046],
            [38.409045970000079, 46.209118974000035],
            [38.002963317000024, 46.602969113000029],
            [38.602117718000045, 46.725483734000079],
            [38.788450651000062, 46.876681104000056],
          ],
          [
            [39.674210172000073, 44.004575365000051],
            [40.198878424000043, 43.745360925000057],
            [40.331372828000042, 44.651456319000033],
            [40.671203267000067, 44.514776181000059],
            [40.719701818000033, 44.728973695000036],
            [40.344351195000058, 45.121633888000076],
            [39.778174827000043, 45.177697117000037],
            [38.762624501000062, 44.924086092000039],
            [39.755284827000025, 44.920503140000051],
            [39.922236210000051, 44.306644745000028],
            [39.674210172000073, 44.004575365000051],
          ],
        ],
      },
      properties: {
        name: "Краснодарский край",
        otherInfo: "ЮФУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f130-79a7-9671-fc8cca91c97a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.646739297000067, 47.586247159000038],
            [43.897362440000052, 47.322733296000081],
            [44.250413848000051, 47.491345280000075],
            [44.429608076000079, 47.307414864000066],
            [43.942656150000062, 46.37492925500004],
            [43.611951586000032, 46.29748158700005],
            [42.15322199600007, 46.621072635000075],
            [41.710817562000045, 46.101176649000081],
            [41.206235962000051, 45.94885012900005],
            [39.976027515000055, 46.757089601000075],
            [38.93737275400008, 46.69275895700008],
            [38.788450651000062, 46.876681104000056],
            [38.932010122000065, 46.985027451000065],
            [38.936655704000032, 46.98853353700008],
            [39.136390268000071, 47.137906860000044],
            [39.136420692000058, 47.13792961300004],
            [39.20355302300004, 47.187785290000079],
            [39.129796560000045, 47.192608451000069],
            [39.129766596000024, 47.19261033500004],
            [38.831592077000039, 47.211362527000063],
            [38.826248860000078, 47.211698562000038],
            [38.291341746000057, 47.242369945000064],
            [38.714392102000033, 47.706937012000026],
            [39.203142460000038, 47.803978364000045],
            [39.673383114000046, 47.894020001000058],
            [39.676555694000058, 47.894627486000047],
            [39.742005327000072, 47.906902553000066],
            [39.742039100000056, 47.906908822000048],
            [39.944131816000038, 47.944418966000057],
            [39.872524908000059, 48.681366503000049],
            [40.151722097000061, 48.896167251000065],
            [39.900461636000045, 49.045129112000041],
            [40.23756561600004, 49.25835070200003],
            [40.184682763000069, 49.555847469000071],
            [40.854331352000031, 49.706275922000032],
            [41.406164520000061, 50.25207434400005],
            [42.062461308000024, 49.742781261000061],
            [42.06439170200008, 49.178460930000028],
            [42.718368627000075, 48.822763413000075],
            [42.105734655000049, 48.194615512000041],
            [43.052661550000039, 47.592909020000036],
            [43.646739297000067, 47.586247159000038],
          ],
        ],
      },
      properties: {
        name: "Ростовская область",
        otherInfo: "ЮФУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f130-79a7-9671-fc8cca91c97a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.909633462000045, 51.274071924000054],
            [43.331933414000048, 51.057486041000061],
            [44.930157370000074, 51.115817237000044],
            [45.399690889000055, 50.601592278000055],
            [45.909787751000067, 50.720891241000061],
            [47.463541529000054, 50.339592759000027],
            [47.465519710000081, 50.339083838000079],
            [47.468362485000057, 50.33835248400004],
            [47.446520197000041, 50.312921361000065],
            [47.425773250000077, 50.28876555100004],
            [47.063027584000054, 49.860426539000059],
            [47.067049080000061, 49.798546309000074],
            [47.067527796000036, 49.79104588000007],
            [47.109704990000068, 49.130222107000066],
            [46.739218660000063, 48.587141845000076],
            [46.640981024000041, 48.623313858000074],
            [46.638973449000048, 48.624053066000045],
            [46.063620358000037, 48.832201256000076],
            [44.994847495000045, 48.31116726700003],
            [44.861448372000041, 48.111047469000027],
            [44.517123876000028, 48.268957741000065],
            [44.233725565000043, 47.986217345000057],
            [43.646739297000067, 47.586247159000038],
            [43.052661550000039, 47.592909020000036],
            [42.105734655000049, 48.194615512000041],
            [42.718368627000075, 48.822763413000075],
            [42.06439170200008, 49.178460930000028],
            [42.062461308000024, 49.742781261000061],
            [41.406164520000061, 50.25207434400005],
            [41.269568384000024, 50.864887255000042],
            [42.909633462000045, 51.274071924000054],
          ],
        ],
      },
      properties: {
        name: "Волгоградская область",
        otherInfo: "ЮФУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f17d-7e22-9d0b-e5edd3c68431",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [54.950441867000052, 53.18296812300008],
            [55.60208960600005, 52.418754284000045],
            [56.253078778000031, 52.478607365000073],
            [56.659669482000027, 51.920899859000031],
            [56.905156626000064, 51.610451786000056],
            [58.366334402000064, 51.697872620000055],
            [58.815601917000038, 52.418210313000031],
            [59.883618824000052, 52.436724867000066],
            [60.047575063000068, 52.001939481000079],
            [60.047854433000055, 52.00118838700007],
            [60.073176170000067, 51.933110274000057],
            [61.573367423000036, 51.347643694000055],
            [61.555729002000078, 51.286904333000052],
            [61.555588168000043, 51.28641649900004],
            [61.50876312500003, 51.124219860000039],
            [61.507946046000029, 51.121352270000045],
            [61.412500680000051, 50.786380743000052],
            [60.201848467000048, 50.789625283000078],
            [59.682401001000073, 50.554695825000067],
            [59.662228904000074, 50.565816296000037],
            [59.662117315000046, 50.565877813000043],
            [58.50201267500006, 51.187001382000062],
            [57.993359856000041, 51.061632485000075],
            [57.98226134500004, 51.058816534000073],
            [57.541910944000051, 50.947089383000048],
            [56.982418627000072, 51.046142299000053],
            [56.973876964000056, 51.047654521000027],
            [56.851274370000056, 51.068789441000035],
            [56.80178316000007, 51.044073920000073],
            [56.801338361000035, 51.043846884000061],
            [55.97137155300004, 50.620211686000061],
            [55.839070072000027, 50.670268864000036],
            [55.837596341000051, 50.670826460000058],
            [54.912665247000064, 51.011456237000061],
            [54.871913114000051, 50.914030913000033],
            [54.870600936000073, 50.910859213000037],
            [54.740879723000035, 50.597306600000081],
            [53.769672238000055, 51.265993092000031],
            [53.741838378000068, 51.285156995000079],
            [53.430123398000035, 51.492503394000039],
            [53.075244159000079, 51.49378970600003],
            [53.044499766000058, 51.493798544000072],
            [52.862562135000076, 51.493850846000043],
            [52.488964607000071, 51.668314184000053],
            [52.472187416000054, 51.676148831000035],
            [52.263355141000034, 51.772234299000047],
            [52.083224032000032, 51.716474873000038],
            [52.076900595000041, 51.714478525000061],
            [51.531441215000029, 51.54227359500004],
            [51.124899201000062, 51.698700019000057],
            [51.118683616000055, 51.701091609000059],
            [50.937194444000056, 51.769881069000064],
            [52.035625008000068, 53.093000396000036],
            [52.583705020000082, 54.382211633000054],
            [53.439286478000042, 54.036809257000073],
            [54.095993058000033, 53.476429758000052],
            [54.950441867000052, 53.18296812300008],
          ],
        ],
      },
      properties: {
        name: "Оренбургская область",
        otherInfo: "ОГУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f0eb-7517-bf08-d338cfe72284",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.586274764000052, 54.189843851000035],
            [86.930671296000071, 53.472999862000052],
            [86.78275506600005, 53.21257087500004],
            [87.123467099000038, 52.691022774000032],
            [86.70819134900006, 52.492091965000043],
            [86.730923679000057, 52.161018545000047],
            [85.828482106000081, 52.001553350000052],
            [85.407711899000049, 51.610863438000081],
            [84.118540475000032, 51.13683011300003],
            [84.565414738000072, 50.816616134000071],
            [84.214257853000049, 50.670570866000048],
            [84.059855992000053, 50.741359147000026],
            [84.057338509000033, 50.742493042000035],
            [83.497245974000066, 50.994763333000037],
            [82.464881473000048, 50.888901388000079],
            [82.253969845000086, 50.867273838000074],
            [82.225136347000046, 50.864317159000052],
            [81.907960510000066, 50.828636467000081],
            [81.906954806000044, 50.82852013300004],
            [81.721488066000063, 50.807066385000041],
            [81.47975430300005, 51.007192092000082],
            [81.468769878000046, 51.016285839000034],
            [81.091794995000043, 51.322860912000067],
            [80.921537521000062, 51.244417828000053],
            [80.917316, 51.242425787000059],
            [80.127856983000072, 50.869897863000062],
            [80.090967773000045, 50.919191959000045],
            [80.09061633500005, 50.919661577000056],
            [78.771310682000035, 52.594486296000071],
            [78.043779937000068, 53.147166751000043],
            [77.996546413000033, 53.182081289000052],
            [77.792172644000061, 53.333152292000079],
            [77.827725894000082, 53.373945148000075],
            [77.828762616000063, 53.375134656000057],
            [78.01277589700004, 53.584591679000027],
            [79.438453595000055, 53.74722299900003],
            [80.657906394000065, 54.307740223000053],
            [81.014694364000036, 54.258457488000033],
            [81.838213887000052, 53.939374736000048],
            [81.859218886000065, 53.715437011000063],
            [82.524177572000042, 53.573423640000044],
            [83.057920279000029, 53.956664654000065],
            [84.539437749000058, 54.145647340000039],
            [85.080624999000065, 54.459460728000067],
            [85.586274764000052, 54.189843851000035],
          ],
        ],
      },
      properties: {
        name: "Алтайский край",
        otherInfo: "НГУЭУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f168-7b61-8e2f-faa6d7273c56",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.710460356000056, 56.326094366000063],
            [42.767845538000074, 56.07641656900006],
            [41.76794000600006, 55.078920976000063],
            [40.198463381000067, 55.269008215000042],
            [39.85368481200004, 55.772838388000082],
            [38.617289519000053, 56.051971921000074],
            [38.421601215000067, 56.46316670200008],
            [38.340593285000068, 56.687869853000052],
            [39.349756488000025, 56.754512706000071],
            [39.997141612000064, 56.469758641000055],
            [42.710460356000056, 56.326094366000063],
          ],
        ],
      },
      properties: {
        name: "Владимирская область",
        otherInfo: "ВИТИГ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f0eb-7517-bf08-d338cfe72284",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [78.639859370000067, 57.127657832000068],
            [80.562186273000066, 56.452918254000053],
            [81.162363389000063, 56.528827164000063],
            [81.627921245000039, 56.279515325000034],
            [83.09818639000008, 56.526171401000056],
            [83.430609416000038, 55.731912589000046],
            [84.479275570000084, 55.987213331000078],
            [85.080624999000065, 54.459460728000067],
            [84.539437749000058, 54.145647340000039],
            [83.057920279000029, 53.956664654000065],
            [82.524177572000042, 53.573423640000044],
            [81.859218886000065, 53.715437011000063],
            [81.838213887000052, 53.939374736000048],
            [81.014694364000036, 54.258457488000033],
            [80.657906394000065, 54.307740223000053],
            [79.438453595000055, 53.74722299900003],
            [78.01277589700004, 53.584591679000027],
            [77.828762616000063, 53.375134656000057],
            [77.827725894000082, 53.373945148000075],
            [77.792172644000061, 53.333152292000079],
            [76.539469954000083, 53.928384195000035],
            [76.665594570000053, 54.091967806000071],
            [76.666904256000066, 54.093666469000027],
            [76.876459390000036, 54.361806696000031],
            [76.68653960000006, 54.315256821000048],
            [76.685136103000048, 54.314912820000075],
            [75.89090073300008, 54.113925704000053],
            [75.772519157000033, 54.083968373000062],
            [75.68760575500005, 54.061862316000031],
            [75.164345356000069, 55.613538104000042],
            [75.340728089000038, 55.982038205000038],
            [76.169276450000041, 56.225822434000065],
            [76.125394313000072, 57.246796595000035],
            [78.639859370000067, 57.127657832000068],
          ],
        ],
      },
      properties: {
        name: "Новосибирская область",
        otherInfo: "НГУЭУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.449756358000059, 66.647843992000048],
            [32.083260303000031, 66.414869312000064],
            [33.189194490000034, 66.581538016000081],
            [34.746464550000042, 65.90491421400003],
            [34.894681131000027, 65.001894209000056],
            [34.914373645000069, 64.876314892000039],
            [34.928946332000066, 64.783384750000039],
            [36.290586832000031, 64.089749647000076],
            [36.44709234100003, 64.010023967000052],
            [36.524594059000037, 63.96877192200003],
            [35.956169356000032, 63.481997153000066],
            [36.444361652000055, 62.89394726900008],
            [37.481567449000067, 62.552077351000037],
            [37.747347398000045, 61.523169733000032],
            [36.722237404000055, 61.526967085000081],
            [35.72972160300003, 61.202911670000049],
            [33.870518964000041, 61.162736936000044],
            [32.798522210000044, 60.69862433000003],
            [29.41128866400004, 61.303183022000042],
            [29.347507964000044, 61.314566700000057],
            [29.287273420000076, 61.324168745000065],
            [31.439473160000034, 62.903150921000076],
            [31.440560221000055, 62.903948454000044],
            [31.486178461000065, 62.935128803000055],
            [30.167021282000064, 63.717359946000045],
            [30.51574964200006, 64.163377633000039],
            [29.662006865000023, 64.954129075000083],
            [29.899311238000053, 65.400593463000064],
            [29.912158990000023, 65.42476522100003],
            [30.106384068000068, 65.777838346000067],
            [29.433693743000049, 66.573250165000047],
            [29.390142879000052, 66.624746181000035],
            [29.393485797000039, 66.624808217000066],
            [31.449756358000059, 66.647843992000048],
          ],
        ],
      },
      properties: {
        name: "Республика Карелия",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.789155957000048, 66.517694508000034],
            [44.07857285700004, 65.978549763000046],
            [44.107045609000068, 66.381982146000041],
            [47.307162725000069, 65.79228979700008],
            [48.175408453000045, 66.120814151000047],
            [49.020431199000029, 66.086847123000041],
            [49.060935208000046, 65.298910364000051],
            [49.665291170000046, 65.236972696000066],
            [50.450066280000044, 64.785523180000041],
            [50.333929572000045, 64.57637664400005],
            [48.313851146000047, 64.228256830000078],
            [47.708640987000081, 64.365850305000038],
            [45.483909138000058, 64.164690252000071],
            [47.026869442214938, 63.612317313221602],
            [47.460236767000026, 63.106749931000081],
            [47.054495320000058, 62.764592883000034],
            [47.316933526000071, 62.211735869000051],
            [48.186760624000044, 62.385237448000055],
            [48.690538475000039, 62.813444703000073],
            [49.505055411000058, 62.702971418000061],
            [49.289098668000065, 61.24589072200007],
            [48.363902170000074, 60.834614898000041],
            [47.066857017000075, 60.85062780100003],
            [45.610933805000059, 61.138894242000049],
            [44.980377714000042, 60.902134539000031],
            [43.898324313000046, 60.994724141000063],
            [43.263749440000026, 60.711445440000034],
            [41.616869867000048, 60.878943657000036],
            [39.124097445000075, 60.672798257000068],
            [38.350414658000034, 60.929960566000034],
            [37.747347398000045, 61.523169733000032],
            [37.481567449000067, 62.552077351000037],
            [36.444361652000055, 62.89394726900008],
            [35.956169356000032, 63.481997153000066],
            [36.524594059000037, 63.96877192200003],
            [37.475237896000067, 63.870920682000076],
            [37.972074580000026, 64.100534010000047],
            [36.810681690000024, 64.748995932000071],
            [36.777133455000069, 65.11772359400004],
            [38.682367173000046, 64.711251300000072],
            [40.428441746000033, 64.667284351000035],
            [39.840434573000039, 65.529530699000077],
            [39.83938611700006, 65.531042343000081],
            [39.838393672000052, 65.53244572400007],
            [42.000947518000032, 66.445377532000066],
            [42.789155957000048, 66.517694508000034],
          ],
        ],
      },
      properties: {
        name: "Архангельская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [57.192101946000037, 70.573365284000033],
            [54.981455801000038, 70.568243992000077],
            [53.895143894000057, 70.75399965500003],
            [53.844601553000075, 70.762642224000047],
            [53.767361712000081, 70.775435164000044],
            [53.487926611000034, 71.482026591000078],
            [51.802634155000078, 71.497359574000029],
            [51.567805075000081, 71.603642880000052],
            [51.56691462100008, 71.60404132900004],
            [51.431399581000051, 71.664679957000033],
            [51.484817676000034, 71.907463281000048],
            [51.485127610000063, 71.90887192200006],
            [51.505431815000065, 71.999464232000037],
            [51.676341692000051, 72.061168132000034],
            [51.682562527000073, 72.063414051000052],
            [52.471315093000044, 72.340718658000071],
            [52.47148261500007, 72.340777554000056],
            [52.916069332000063, 72.491887624000071],
            [52.624318918000029, 72.782841347000044],
            [53.568053878000057, 73.18786693800007],
            [55.187090458000057, 73.338976684000045],
            [56.350829654000052, 73.143525891000081],
            [55.625304490000076, 72.745372582000073],
            [55.55378507000006, 72.458619857000031],
            [55.55241219100003, 72.45292223000007],
            [55.477447980000079, 72.141811027000074],
            [55.476520662000041, 72.137845738000067],
            [55.427420150000046, 71.927887926000039],
            [55.549239128000067, 71.841016643000046],
            [55.551844304000042, 71.839158845000043],
            [56.36955295000007, 71.231371642000056],
            [56.399565220000056, 71.207575205000069],
            [56.999910425000053, 70.731567341000073],
            [57.093222905000061, 70.65475736500008],
            [57.192101946000037, 70.573365284000033],
          ],
        ],
      },
      properties: {
        name: "Архангельская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.641468958000075, 76.66452483300003],
            [67.654937335000056, 76.167477606000034],
            [67.650461621000034, 76.165128419000041],
            [67.408384622000028, 76.135819819000062],
            [65.758109033000039, 75.894611249000036],
            [63.940831207000031, 75.628993197000057],
            [60.464965946000063, 75.120951694000041],
            [60.505404494000061, 74.948900307000031],
            [60.509366757000066, 74.93176104500003],
            [60.522899462000055, 74.873223650000057],
            [59.20181556600005, 74.531119164000074],
            [59.055209226000045, 74.493154363000031],
            [59.041717087000052, 74.494539609000071],
            [58.250089234000029, 74.573892471000079],
            [58.510775456000033, 74.320291933000078],
            [58.511475634000078, 74.319594681000069],
            [58.608552865000036, 74.222923018000074],
            [58.297729399000048, 74.191596079000078],
            [58.284988870000063, 74.190312],
            [57.58046629200004, 74.117077248000044],
            [57.820445642000038, 73.894821454000066],
            [57.830341309000062, 73.885498127000062],
            [57.863309336000043, 73.85443688600003],
            [57.773891515000059, 73.84852849300006],
            [57.746592373000055, 73.846724668000036],
            [57.212208538000027, 73.810460496000076],
            [56.941416515000071, 73.308466637000038],
            [54.209881728000028, 73.382845485000075],
            [54.08091560500003, 73.478478774000052],
            [54.074367525000071, 73.483334425000066],
            [53.751641220000067, 73.71744917400008],
            [53.75065500200003, 73.718164604000037],
            [53.721053655000048, 73.73927960900005],
            [55.550527404000036, 74.08525867000003],
            [55.592497682000044, 74.51385854800003],
            [55.593195632000061, 74.520986006000044],
            [55.603334380000035, 74.62103512300007],
            [55.848660887000051, 74.71775621300003],
            [55.849714084000027, 74.718171441000038],
            [56.373252653000065, 74.919641865000074],
            [55.922852171000045, 75.143597646000046],
            [57.500631873000032, 75.330587939000054],
            [57.802115428000036, 75.562730903000045],
            [60.600581484000031, 76.001191756000082],
            [61.209474328000056, 76.254100837000067],
            [61.210336667000036, 76.254459017000045],
            [61.219509954000046, 76.258187445000033],
            [61.262980583000058, 76.257812432000037],
            [61.273455646000059, 76.257698898000058],
            [62.271785219000037, 76.246878532000039],
            [65.495455978000052, 76.489884695000057],
            [66.95041475000005, 76.784655110000074],
            [67.54773595100005, 76.899020164000035],
            [67.738427211000044, 76.93553053100004],
            [67.841537574000029, 76.918633465000084],
            [67.905495579000046, 76.90815243600008],
            [68.33976425600008, 76.835910238000054],
            [68.345996617000083, 76.834873462000076],
            [68.825997503000053, 76.753186594000056],
            [68.643978468000057, 76.665789207000046],
            [68.641468958000075, 76.66452483300003],
          ],
        ],
      },
      properties: {
        name: "Архангельская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [58.644517399000051, 80.252364861000046],
            [56.750224317000061, 80.205281207000041],
            [57.29343947600006, 80.603159136000045],
            [58.644517399000051, 80.252364861000046],
          ],
        ],
      },
      properties: {
        name: "Архангельская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.847328460000028, 80.284171651000065],
            [47.434811989000082, 80.096881038000049],
            [47.420138166000072, 80.510743342000069],
            [48.505289975000039, 80.537666793000085],
            [48.50574118000003, 80.537677988000041],
            [48.582483880000041, 80.539444165000077],
            [48.614352610000026, 80.540177601000039],
            [48.860018384000057, 80.54570126200008],
            [49.094151486000044, 80.671777526000085],
            [49.331788826000036, 80.795027915000048],
            [49.492522644000076, 80.878392363000046],
            [49.696897406000062, 80.858901900000035],
            [49.775502004000032, 80.851405671000066],
            [50.295141505000061, 80.80084425900003],
            [50.929197600000066, 80.787458893000064],
            [50.944846746000053, 80.787128529000086],
            [51.409815237000032, 80.776558480000062],
            [51.347686300000078, 80.726496345000044],
            [51.346778249000067, 80.725755136000032],
            [51.264891686000055, 80.658914190000075],
            [51.161842079000053, 80.573382206000076],
            [50.12132805400006, 80.44760692400007],
            [49.652814742000032, 80.390973959000064],
            [48.847328460000028, 80.284171651000065],
          ],
        ],
      },
      properties: {
        name: "Архангельская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [61.776381016000073, 80.897550881000029],
            [60.965461357000038, 80.381592824000052],
            [59.583686124000053, 80.454022654000084],
            [59.755686297000068, 80.813095477000047],
            [61.776381016000073, 80.897550881000029],
          ],
        ],
      },
      properties: {
        name: "Архангельская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [64.642820030000053, 80.740480597000044],
            [63.235356556000056, 80.644859987000075],
            [63.31583484500004, 81.031982098000071],
            [64.52505155700004, 81.204399098000067],
            [65.148342017000061, 81.174932732000059],
            [64.642820030000053, 80.740480597000044],
          ],
        ],
      },
      properties: {
        name: "Архангельская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.585893716000044, 80.764808126000048],
            [54.942838117000065, 80.714936924000085],
            [54.733204527000055, 80.996961125000041],
            [56.91788659000008, 81.469122811000034],
            [57.179759900000079, 81.24936209100008],
            [56.585893716000044, 80.764808126000048],
          ],
        ],
      },
      properties: {
        name: "Архангельская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f0eb-7517-bf08-d338cfe72284",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.04792032000006, 51.579178904000059],
            [88.566274931000066, 51.36740577900008],
            [88.794364041000051, 51.566482393000058],
            [89.781467206000059, 50.590583637000066],
            [89.441443021000055, 50.206552657000032],
            [89.755417635000072, 49.899270686000079],
            [89.781759709000085, 49.873490117000074],
            [89.832758493000085, 49.822974185000078],
            [88.01906976500004, 49.179801780000048],
            [87.795027091000065, 49.182603435000033],
            [87.794385606000048, 49.182609477000028],
            [87.524269037000067, 49.185153683000067],
            [87.458249987000045, 49.23563093000007],
            [87.458034242000053, 49.235795886000062],
            [87.127106818000073, 49.486136461000058],
            [87.120501709000052, 49.491041364000068],
            [86.76572068400003, 49.754499042000077],
            [85.975339804000043, 49.581426106000038],
            [85.595060982000064, 49.698446802000035],
            [85.59404255700008, 49.698760195000034],
            [85.510182754000084, 49.724234811000031],
            [85.509633713000085, 49.724400760000037],
            [85.413294277000034, 49.753519562000065],
            [84.558546169000067, 50.197410066000032],
            [84.491509475000043, 50.290542579000032],
            [84.491173759000048, 50.291008982000051],
            [84.214257853000049, 50.670570866000048],
            [84.565414738000072, 50.816616134000071],
            [84.118540475000032, 51.13683011300003],
            [85.407711899000049, 51.610863438000081],
            [85.828482106000081, 52.001553350000052],
            [86.730923679000057, 52.161018545000047],
            [86.70819134900006, 52.492091965000043],
            [87.123467099000038, 52.691022774000032],
            [88.375173651000068, 52.380353248000063],
            [88.428678577000085, 52.240968915000053],
            [88.04792032000006, 51.579178904000059],
          ],
        ],
      },
      properties: {
        name: "Республика Алтай",
        otherInfo: "НГУЭУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f15b-7bf3-8c96-40936e3e6535",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.307609030000037, 69.535794599000042],
            [33.405954959000042, 69.210246651000034],
            [34.420506351000029, 69.245360401000028],
            [34.461286588000064, 69.246771809000052],
            [34.462406778000059, 69.246802698000067],
            [34.462811025000065, 69.246813849000034],
            [35.598086248000072, 69.278115854000077],
            [35.598103758000036, 69.278116337000029],
            [35.598214044000031, 69.278119378000042],
            [35.59821617700004, 69.278118827000071],
            [35.598216554000032, 69.278118730000074],
            [36.378270526000051, 69.076473125000064],
            [36.378271100000063, 69.076472977000037],
            [36.378276884000059, 69.076471481000056],
            [36.378277276000063, 69.076471370000036],
            [36.378277326000045, 69.07647135600007],
            [36.38461662800006, 69.074661130000038],
            [38.37797155800007, 68.505446913000071],
            [39.581366888000048, 68.161810303000038],
            [40.627935587000024, 67.829963428000042],
            [40.909458431000076, 67.552193637000073],
            [40.914417092000065, 67.547197363000066],
            [41.06610201400008, 67.394361887000059],
            [41.066287383000031, 67.394175112000028],
            [41.389047770000047, 67.061311873000079],
            [41.116123664000042, 66.893029355000067],
            [41.115892074000044, 66.892882266000072],
            [40.484991658000069, 66.492181102000075],
            [40.463138779000076, 66.477883269000074],
            [40.214744559000053, 66.315364671000054],
            [40.060446637000041, 66.287296259000072],
            [40.058375179000052, 66.28691943900003],
            [39.548223558000075, 66.192402308000055],
            [39.458403720000035, 66.175152051000055],
            [38.736898548000056, 66.036584112000071],
            [38.661327868000058, 66.044989224000062],
            [38.654878789000065, 66.045706503000076],
            [37.464662342000054, 66.17182639300006],
            [37.253813237000031, 66.191776445000073],
            [35.854730175000043, 66.324154434000036],
            [34.371565966000048, 66.682506896000064],
            [34.27457928900003, 66.705940184000042],
            [32.181270049000034, 67.166888116000052],
            [33.189194490000034, 66.581538016000081],
            [32.083260303000031, 66.414869312000064],
            [31.449756358000059, 66.647843992000048],
            [29.393485797000039, 66.624808217000066],
            [29.390142879000052, 66.624746181000035],
            [29.383158043000037, 66.634687123000049],
            [29.122350247000043, 66.999651268000036],
            [29.121486988000072, 67.000859279000053],
            [29.091003246000071, 67.042740692000052],
            [29.893859876000079, 67.653684473000055],
            [29.454172242000027, 67.925953892000052],
            [29.418675830000041, 67.947934468000028],
            [28.445943637000028, 68.364612942000065],
            [28.59192955900005, 69.064776923000068],
            [29.384696591000079, 69.227519774000029],
            [31.101061037000079, 69.558098324000071],
            [32.093117129000063, 69.892935333000082],
            [32.093862169000033, 69.892848033000064],
            [32.203108375000056, 69.880047163000029],
            [32.207598775000065, 69.879521004000082],
            [32.822375022000074, 69.805793904000041],
            [32.655510830000026, 69.719235408000031],
            [32.649175582000055, 69.715894974000037],
            [32.307609030000037, 69.535794599000042],
          ],
        ],
      },
      properties: {
        name: "Мурманская область",
        otherInfo: "РГГМУ",
      },
    },

    {
      type: "Feature",
      clusterId: "0193c514-f173-7a28-ab26-e9964b6d0cf2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.994847495000045, 48.31116726700003],
            [46.113982141000065, 47.540797844000053],
            [46.765664437000055, 47.558592735000047],
            [46.653809036000041, 47.258881864000045],
            [47.192567687000064, 46.743477609000081],
            [46.844185940000045, 46.45002351100004],
            [47.134067673000061, 46.194762549000075],
            [46.94782755500006, 45.73821610400006],
            [47.464299424000046, 45.657936846000041],
            [47.101608288000079, 45.052470138000047],
            [47.097606797000026, 45.045640061000029],
            [46.930555724000044, 44.760503416000063],
            [46.841993148000029, 44.778983020000055],
            [46.841566532000058, 44.779072038000038],
            [45.699868328000036, 45.006329780000044],
            [43.22173039200004, 46.193649361000041],
            [41.710817562000045, 46.101176649000081],
            [42.15322199600007, 46.621072635000075],
            [43.611951586000032, 46.29748158700005],
            [43.942656150000062, 46.37492925500004],
            [44.429608076000079, 47.307414864000066],
            [44.250413848000051, 47.491345280000075],
            [43.897362440000052, 47.322733296000081],
            [43.646739297000067, 47.586247159000038],
            [44.233725565000043, 47.986217345000057],
            [44.517123876000028, 48.268957741000065],
            [44.861448372000041, 48.111047469000027],
            [44.994847495000045, 48.31116726700003],
          ],
        ],
      },
      properties: {
        name: "Республика Калмыкия",
        otherInfo: "КалмГУ",
      },
    },
  ],
};
