import { InfoWindow } from "../../Map/config/interfaces";

interface ListItem {
  title: string;
  alias?: string;
  value?: string;
  color?: string;
}

export const getPopupData = (
  infowindow: InfoWindow,
  feature_properties: Record<string, any>,
) => {
  Object.freeze(infowindow);

  const popupData: Partial<InfoWindow> = { ...infowindow };

  const keysToFetch: Array<keyof InfoWindow> = [
    "title",
    "subTitle",
    "description",
    "paragraph",
    "subscription",
    "image",
    "buttonTitle"
  ];

  for (const key of keysToFetch) {
    if (infowindow[key]) {
      const propertyKey = infowindow[key] as string;
      popupData[key] = feature_properties[propertyKey] ?? infowindow[key];
      if (feature_properties.hasOwnProperty(propertyKey)) {
        popupData[key] = feature_properties[propertyKey]
      }
    }
  }

  if (infowindow.smallPopup) {
    popupData.smallPopup = true;
  }

  if (infowindow.colorSubTitle) {
    popupData.colorSubTitle = true;
  }

  // Обработка ключа list
  if (Array.isArray(infowindow.list)) {
    popupData.list = infowindow.list
      .map((item): ListItem | null => {
        let { title, alias, value: existingValue, color } = item as ListItem;

        // Если значение уже задано, используем его
        if (existingValue !== undefined) {
          return { title, value: existingValue, color };
        }

        // Если значения нет, пытаемся получить его из feature_properties
        const rawValue = feature_properties[title];
        if (rawValue !== undefined) {
          if (rawValue !== null) {
            if (alias) {
              title = alias;
            }
            return { title, value: String(rawValue), color };
          }
        }

        // Исключаем элементы без значения
        return null;
      })
      .filter((item): item is ListItem => item !== null); // Убираем null из списка
  }

  return popupData as InfoWindow;
};
