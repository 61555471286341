import styles from "./MapControls.module.scss";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { setPostNull } from "../../redux/post";
import { setDefaultMap } from "../../redux/map";
import { getTokensFromStorage, removeTokens } from "../../auth/tokenService";
import { useLocation } from "react-router-dom";
import { logout } from "../../redux/auth/auth.reducer";
import MenuButton from "../MenuButton/MenuButton";

export type MenuButtonType = {
  title: string;
  path?: string;
  action: () => void;
};

export default function MapControls() {
  const navigate = useNavigate();
  const location = useLocation();
  const tokens = getTokensFromStorage();
  const dispatch = useDispatch<AppDispatch>();

  const userInfo = useSelector((store: RootState) => store.auth.userInfo);

  const userActions: { [key: string]: () => void } = {
    user: () => {
      // console.log("Пользователь");
    },
    logout: () => {
      removeTokens();
      dispatch(logout());
      navigate("/");
    },
    login: () => {
      navigate("/login");
    },
  };

  const userOptions: { [key: string]: string } = tokens
    ? { user: "Пользователь", logout: "Выйти" }
    : { login: "Войти" };

  const handleUserSelect = (option: string) => {
    const action = userActions[option];
    if (action) action();
  };

  const mainActions: { [key: string]: () => void } = {
    map: () => {
      dispatch(setPostNull());
      dispatch(setDefaultMap());
      navigate(`/map`);
    },
    constructor: () => {
      navigate(`/constructor`);
    },
    news: () => {
      navigate("/news");
    },
    about: () => {
      navigate("/about");
    },
    management: () => {
      navigate("/management");
    },
  };

  const constructorOption: { [key: string]: string } = userInfo
    ? { constructor: "Конструктор" }
    : {};

  const mainOptions: { [key: string]: string } = {
    map: "Путеводитель",
    ...constructorOption,
    news: "Новости",
    about: "О нас",
  };

  if (userInfo && userInfo?.role !== "user") {
    mainOptions["management"] = "Управление";
  }

  const handleMainSelect = (option: string) => {
    const action = mainActions[option];
    if (action) action();
  };

  const mainSelectedOption = Object.keys(mainOptions).find((key) =>
    location.pathname.includes(key)
  );

  return (
    <div className={styles.buttonsContainer}>
      <MenuButton
        options={mainOptions}
        onSelect={handleMainSelect}
        selectedOption={mainSelectedOption}
        type={"burgerMenu"}
      />
      <MenuButton
        options={userOptions}
        onSelect={handleUserSelect}
        type={"userMenu"}
      />
      <div className={styles.searchButton} />
    </div>
  );
}
