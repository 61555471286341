import { useNavigate } from "react-router-dom";

export function useMenuEdit() {
  const navigate = useNavigate();

  const menuEditActions: { [key: string]: () => void } = {
    map: () => {
      navigate(`/map`);
    },
    management: () => {
      navigate(`/management`);
    },
    addMap: () => {
      navigate(`/edit/layer`);
    },
    addPost: () => {
      navigate(`/edit/map`);
    },
    file: () => {
      navigate("/edit/file");
    },
    modal: () => {
      navigate("/edit/modal");
    },
  };

  const menuEditOptions: { [key: string]: string } = {
    map: "Путеводитель",
    management: "Управление",
    addMap: "Редактор слоя",
    addPost: "Редактор карты",
    file: "Редактор файлов",
    modal: "Модальное окно",
  };

  const handleMenuEditSelect = (option: string): void => {
    const action = menuEditActions[option];
    if (action) action();
  };

  return { menuEditOptions, handleMenuEditSelect };
}
