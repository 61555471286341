import React from "react";
import styles from "./CircleLoader.module.scss";

const CircleLoader: React.FC = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader} />
    </div>
  );
};

export default CircleLoader;
