import React from "react";
import { Layer } from "./config/interfaces";
import PointLayer from "./wfs/PointWFSLayer";
import PolygonLayer from "./wfs/PolygonWFSLayer";
import CartogramLayer from "./wfs/CartogramWFSLayer";
import LineLayer from "./wfs/LineWFSLayer";
import PieIconLayer from "./wfs/PieIconWFSLayer";
import SvgLayer from "./wfs/SvgWFSLayer";
import PolygonHatchedLayer from "./wfs/PolygonHatchedWFSLayer";
import PointClusterLayer from "./wfs/PointClusterWFSLayer";
import PointClassifyClusterLayer from "./wfs/PointClassifyClusterWFSLayer";
import EulerDiagramLayer from "./wfs/EulerDiagramLayer";
import HalfPieLayer from "./wfs/HalfPieLayer";
import DonatIconLayer from "./wfs/DonatIconWFSLayer";
import CustomRLayerTileWMS from "./wms/CustomWMS";

interface WFSLayerProps {
  url: string;
  style?: React.ReactNode;
  layer: Layer;
  order: number;
}

const WFSLayer: React.FC<WFSLayerProps> = ({ url, layer, style, order }) => {
  // собираем уникальный ключ для слоя, состоящий из uuid карты и имени слоя на геосервере
  const unique_key = layer.layer_id;

  switch (layer.geometry_type) {
    case "wms":
      return (
        <CustomRLayerTileWMS
          key={unique_key}
          url={"https://geo.touristatlas.ru/geoserver/wms"}
          params={{
            LAYERS: `${layer.workspace}:${layer.layer}`,
            FORMAT: "image/png",
            STYLES: layer.marker?.sld ? layer.marker?.sld : null,
            TILED: true,
          }}
          zIndex={order}
        />
      );
    case "points":
      return (
        <PointLayer
          key={unique_key}
          url={url}
          layer={layer}
          order={order}
        />
      );
    case "point":
      return (
        <PointLayer
          key={unique_key}
          url={url}
          layer={layer}
          order={order}
        />
      );
    case "point_cluster":
      return (
        <PointClusterLayer
          key={unique_key}
          url={url}
          layer={layer}
          order={order}
        />
      );
    case "polygon":
      return (
        <PolygonLayer key={unique_key} url={url} layer={layer} order={order} />
      );
    case "polygon_hatched":
      return (
        <PolygonHatchedLayer
          key={unique_key}
          url={url}
          layer={layer}
          order={order}
        />
      );
    case "line":
      return (
        <LineLayer key={unique_key} url={url} layer={layer} order={order} />
      );
    case "cartogram":
      return (
        <CartogramLayer
          key={unique_key}
          url={url}
          layer={layer}
          order={order}
        />
      );
    case "pie":
      return (
        <PieIconLayer key={unique_key} url={url} layer={layer} order={order} />
      );
    case "donat":
      return (
        <DonatIconLayer
          key={unique_key}
          url={url}
          layer={layer}
          order={order}
        />
      );
    case "half_pie":
      return (
        <HalfPieLayer key={unique_key} url={url} layer={layer} order={order} />
      );
    case "euler":
      return (
        <EulerDiagramLayer
          key={unique_key}
          url={url}
          layer={layer}
          order={order}
        />
      );
    case "diagramm":
      return (
        <PointLayer key={unique_key} url={url} layer={layer} order={order} />
      );
    case "svg":
      return (
        <SvgLayer key={unique_key} url={url} layer={layer} order={order} />
      );
    default:
      return null;
  }
};

export default WFSLayer;
