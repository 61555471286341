import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import MapControls from "../components/MapControls/MapControls";

import { setClusterId } from "../redux/map";
import { openSidebar } from "../redux/menu";
import { AppDispatch } from "../store";
import Main from "../components/Map/MapOverLayer";
import styles from "../components/Map/Map.module.css";
import Sidebar from "../components/SideBar/Sidebar";
import { useSidebar } from "../components/SideBar/useSidebar";

import { setIsConstructorMode } from "../redux/constructorMode";

import Constructor from "../components/Constructor/Constructor";

export default function ConstructorPage() {
  const dispatch = useDispatch<AppDispatch>();

  const { isSidebarOpen, isMenu, isConstructorMode } = useSidebar();
  const { id: paramClusterId } = useParams();

  const onSidebarClose = useCallback(() => {
    dispatch(openSidebar(!isSidebarOpen));
  }, [dispatch, isSidebarOpen]);

  useEffect(() => {
    if (!isConstructorMode) {
      dispatch(setIsConstructorMode(true));
    }
  }, [isConstructorMode, dispatch]);

  useEffect(() => {
    if (paramClusterId) {
      dispatch(setClusterId(paramClusterId));
    }
  }, [dispatch, paramClusterId]);

  return (
    <>
      <Main>
        <MapControls />
        <div className={styles.containerLeft} />
        <div className={styles.containerRight}>
          <Sidebar
            isMenu={isMenu}
            isSidebarOpen={isSidebarOpen}
            onSidebarClose={onSidebarClose}
            Content={<Constructor />}
            menuType={"constructor"}
            scrollable={false}
          />
        </div>
      </Main>
    </>
  );
}
