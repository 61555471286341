import React, { useCallback } from 'react';
import { RLayerVector } from 'rlayers';
import { FeatureLike } from 'ol/Feature';
import IconStyle from 'ol/style/Icon';
import Style from 'ol/style/Style';
import useWFSLayer from './useWFSLayer';
import { GeoJSON } from 'ol/format';
import { Layer } from "../config/interfaces";

interface PointLayerProps {
  url: string;
  layer: Layer;
  order: number;
}

const SvgLayer: React.FC<PointLayerProps> = ({ url, layer, order }) => {
  const { layer_url } = useWFSLayer({ url, layer });

  const createSVGIconStyleCallback = useCallback(
    (feature: FeatureLike) => {
      let svgUrl = layer.marker?.svg;

      if (layer.marker?.svg_field) {
        svgUrl = feature.getProperties()[layer.marker?.svg_field];
      }

      if (!svgUrl) {
        return new Style({}); 
      }

      return new Style({
        image: new IconStyle({
          src: svgUrl,
          crossOrigin: "anonymous",
          scale: layer.marker?.size_multiplication ? layer.marker?.size_multiplication : 1,  // no scale! real size!
          anchor: [0.5, 0.5], 
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
        })
      });
    },
    [layer.marker]
  );

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: 'EPSG:4326' })}
      url={layer_url}
      visible={layer.visible}
      style={createSVGIconStyleCallback}
    />
  );
};

export default SvgLayer;
